/*
 *  Document   : bootstrap.js
 *  Author     : pixelcave
 *  Description: Import global dependencies
 *
 */

/*
 ********************************************************************************************
 *
 * If you would like to use webpack to handle all required core JS files, you can uncomment
 * the following imports and window assignments to have them included in the compiled
 * oneui.app.min.js as well.
 *
 * After that change, you won't have to include oneui.core.min.js in your pages any more
 *
 *********************************************************************************************
 */

// Import all vital core JS files..
import jQuery from 'jquery';
import SimpleBar from 'simplebar';
import Cookies from 'js-cookie';
import 'bootstrap';
import 'popper.js';
import 'jquery.appear';
import 'jquery-scroll-lock';
import 'summernote';
import 'summernote/dist/summernote-bs4.css';
import 'select2';


// ..and assign to window the ones that need it
window.jQuery       = jQuery;
window.SimpleBar    = SimpleBar;
window.Cookies      = Cookies;
window.$ = jQuery;
